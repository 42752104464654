










import mixins from 'vue-typed-mixins'
import DialogMixin from '@/components/dialogs/mixins/DialogMixins'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import CCalendarServiceType from '@/components/CCalendarServiceType.vue'

export default mixins(DialogMixin).extend({
  name: 'BookingCalendarServiceTypesDialog',
  components: { CCalendarServiceType },
  data () {
    return {
      types: [] as Array<ServiceType>,
      textColor: null as string | null,
      color: null as string | null,
      callback: null as Function | null
    }
  },
  created () {
    if (this.dialog.data && this.dialog.data.service) {
      this.types = this.dialog.data.service.types
      this.textColor = this.dialog.data.service.textColor
      this.color = this.dialog.data.service.color
      this.callback = this.dialog.data.callback
    }
  },
  methods: {
    update (serviceType: ServiceType) {
      if (this.callback) {
        this.$emit('close')
        this.callback(serviceType)
      }
    }
  }
})
