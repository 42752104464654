





































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
export default mixins(DraftElement).extend({
  props: {
    serviceType: {
      type: ServiceType,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selectedType: null as ServiceType | null
    }
  },
  computed: {
    getPrice (): string|null {
      if (this.serviceType) {
        return `${(this.$options.filters as any).money(this.serviceType.price)}`
      }

      return null
    },
    getDuration (): string|null {
      if (this.serviceType) {
        return `${(this.$options.filters as any).durationFilter(this.serviceType.duration)}`
      }

      return null
    }
  },
  methods: {
    update () {
      this.$emit('change', this.selectedType)
    }
  }
})
